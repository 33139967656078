<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
    name: 'CategoryProduct',
    components: {
        VueSlickCarousel
    },
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/product_default.png"
        }
    },
    data() {
        return {
            sliderSettings: {
                arrows: false,
                dots: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplaySpeed: 3000,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: false,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: false,
                        },
                    },
                ],
            },
            categories: []
        };
    },
    methods: {
        prevSlide() {
            this.$refs.slick.prev();
        },
        nextSlide() {
            this.$refs.slick.next();
        },
    },
    beforeMount() {
        this.categories = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="category__product-wrap" v-if="categories && categories.length > 0">
        <div class="container">
            <div class="category__product">
                <h2 class="section-title-menu">SOURCE BY CATEGORY</h2>
                <div class="category__product-list">
                    <VueSlickCarousel ref="slick"  v-bind="sliderSettings">
                        <div class="single-category__product"  v-for="category in categories" :key="category.id">
                            <div class="category__product-icon">
                                <img :src="category.banner_path ?? defaultImage" alt="">
                            </div>
                            <div class="category__product-details">
                                <a :href="'/categories/' + category.slug + '/products'" class="category-item" >
                                    <h6 class="details-name">{{ category.name }}</h6>
                                </a>
                            </div>
                        </div>
                    </VueSlickCarousel>
                    <div class="category__product-pagination">
                        <div class="category__product-pagination-controls">
                            <button class="category__product-pagination-next" @click="nextSlide">
                                <img
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/43d0cf80b0f1326bf7a71fe72cc885b6e65b31e6a733df1d97dcb3e706a0b9af?apiKey=f4720969da584a5080659c2fbf41bfab&"
                                    alt="Next"
                                    class="category__product-pagination-icon"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
