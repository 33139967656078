<script>
export default {
    name: 'Applications',
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/product_default.png"
        }
    },
    data() {
        return {
            applications: {}
        };
    },
    beforeMount() {
        this.applications = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="applications-wrap" v-if="applications.data && applications.data.length > 0">
        <div class="container">
            <div class="applications">
                <h2 class="section-title-menu">{{applications.title }}</h2>
                <div class="application-list">
                    <div class="single-application"  v-for="item in applications.data" :key="item.id">
                        <div class="application-icon">
                            <a href="/products"><img :src="item.logo?.path" alt=""></a>
                        </div>

                        <div class="application-details">
                            <h6 class="details-name"><a href="/products">{{ item.name }}</a></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
