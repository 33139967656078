import "./vendors/vendors";

$(() => {
    /*      variables
    /*----------------------------------------*/

    let _window = $(window),
        body = $("body");

    /*      button loading
    /*----------------------------------------*/

    $("[data-loading]").on("click", (e) => {
        e.currentTarget.classList.add("btn-loading");
    });

    /*      select option
    /*----------------------------------------*/

    let select = $(".custom-select-option");

    select.niceSelect();

    select.on("change", (e) => {
        e.target.dispatchEvent(
            new Event("nice-select-updated", { bubbles: true })
        );
    });

    /*      overlay
    /*----------------------------------------*/

    let overlay = $(".overlay");

    /*      header
    /*----------------------------------------*/

    let headerWrap = $(".header-wrap"),
        headerWrapInner = $(".header-wrap-inner"),
        headerWrapInnerHeight = headerWrapInner.outerHeight(),
        headerSearchSm = $(".header-search-sm"),
        searchInputSm = $(".search-input-sm"),
        headerSearchSmClose = $(".header-search-sm-form .btn-close");

    headerSearchSm.on("click", (e) => {
        let target = $(e.currentTarget);

        target.parents(".header-search").next().toggleClass("active");
        searchInputSm.trigger("focus");
    });

    headerSearchSmClose.on("click", (e) => {
        let target = $(e.currentTarget);

        target.parents(".header-search-sm-form").removeClass("active");
    });

    _window.on("resize", () => {
        headerWrapInnerHeight = headerWrapInner.outerHeight();
    });

    _window.on("load scroll resize", () => {
        let headerWrapHeight = headerWrap.outerHeight(),
            headerWrapOffsetTop = headerWrap.offset().top + headerWrapHeight;

        function stickyHeader() {
            let scrollTop = _window.scrollTop();

            if (scrollTop > headerWrapOffsetTop) {
                headerWrap.css("padding-top", `${headerWrapInnerHeight}px`);
                headerWrapInner.addClass("sticky");

                setTimeout(() => {
                    headerWrapInner.addClass("show");
                });

                return;
            }

            headerWrap.css("padding-top", 0);
            headerWrapInner.removeClass("sticky show");
        }

        stickyHeader();
    });

    /*      menu dropdown arrow
    /*----------------------------------------*/

    let megaMenuItem = $(".mega-menu > li"),
        subMenuDropdown = $(".sub-menu > .dropdown"),
        sidebarMenuSubMenu = $(".sidebar-menu .sub-menu");

    function menuDropdownArrow(parentSelector, childSelector) {
        parentSelector.each(function () {
            let self = $(this);

            if (self.children().length > 1) {
                if (window.FleetCart.rtl) {
                    self.children(`${childSelector}`).append(
                        '<i class="las la-angle-left"></i>'
                    );

                    return;
                }

                self.children(`${childSelector}`).append(
                    '<i class="las la-angle-right"></i>'
                );
            }
        });
    }

    menuDropdownArrow(subMenuDropdown, "a");
    menuDropdownArrow(megaMenuItem, ".menu-item");

    /*      navigation
    /*----------------------------------------*/

    let moreCategories = $(".more-categories"),
        categoryDropdown = $(".category-dropdown"),
        categoryNavInner = $(".category-nav-inner"),
        categoryDropdownWrap = $(".category-dropdown-wrap"),
        verticalMegaMenuList = $(".vertical-megamenu > li");

    categoryNavInner.on("click", () => {
        categoryDropdownWrap.toggleClass("show");
    });

    _window.on("load resize", () => {
        let verticalMegaMenuListHeight = 0,
            homeSliderHeight = homeSlider.height(),
            categoryDropdownHeight = homeSliderHeight;

        categoryDropdown.css("height", `${categoryDropdownHeight}px`);

        verticalMegaMenuList.each(function () {
            let self = $(this);

            verticalMegaMenuListHeight += self.height();

            if (verticalMegaMenuListHeight + 78 > categoryDropdownHeight) {
                self.addClass("hide");
                moreCategories.removeClass("hide");

                return;
            }

            self.removeClass("hide");
            moreCategories.addClass("hide");
        });
    });

    /*      sidebar menu
    /*----------------------------------------*/

    let sidebarMenuIcon = $(".sidebar-menu-icon"),
        sidebarMenuWrap = $(".sidebar-menu-wrap"),
        sidebarMenuClose = $(".sidebar-menu-close"),
        sidebarMenuTab = $(".sidebar-menu-tab a"),
        sidebarMenuList = $(".sidebar-menu li"),
        sidebarMenuLink = $(".sidebar-menu > li > a"),
        sidebarMenuListUl = $(".sidebar-menu > li > ul"),
        sidebarMenuDropdown = $(".sidebar-menu > .dropdown"),
        sidebarMenuSubMenuUl = $(".sidebar-menu .sub-menu ul"),
        sidebarMenuSubMenuLink = $(".sidebar-menu .sub-menu > a");

    sidebarMenuIcon.on("click", (e) => {
        e.stopPropagation();

        overlay.addClass("active");
        sidebarMenuWrap.addClass("active");
    });

    sidebarMenuClose.on("click", (e) => {
        overlay.removeClass("active");
        sidebarMenuWrap.removeClass("active");
    });

    sidebarMenuWrap.on("click", (e) => {
        e.stopPropagation();
    });

    sidebarMenuTab.on("click", (e) => {
        let target = $(e.currentTarget);

        e.preventDefault();
        target.tab("show");
    });

    sidebarMenuList.each(function () {
        let self = $(this);

        if (self.children().length > 1) {
            if (window.FleetCart.rtl) {
                self.children("a").after('<i class="las la-angle-left"></i>');

                return;
            }

            self.children("a").after('<i class="las la-angle-right"></i>');
        }
    });

    sidebarMenuDropdown.on("click", (e) => {
        let target = $(e.currentTarget);

        if (!target.hasClass("active")) {
            $(".sidebar-menu > li").removeClass("active");
            target.addClass("active");
        } else {
            $(".sidebar-menu > li").removeClass("active");
        }

        if (!target.children("ul").hasClass("open")) {
            $(".sidebar-menu .open").removeClass("open").slideUp(300);
            target.children("ul").addClass("open").slideDown(300);

            return;
        }

        $(".sidebar-menu .open").removeClass("open").slideUp(300);
    });

    sidebarMenuLink.on("click", (e) => {
        e.stopPropagation();
    });

    sidebarMenuListUl.on("click", (e) => {
        e.stopPropagation();
    });

    sidebarMenuSubMenu.on("click", (e) => {
        let target = $(e.currentTarget);

        if (!target.hasClass("active")) {
            target.addClass("active");
        } else {
            target.removeClass("active");
        }

        target.children("ul").slideToggle(300);
    });

    sidebarMenuSubMenuUl.on("click", function (e) {
        e.stopPropagation();
    });

    sidebarMenuSubMenuLink.on("click", (e) => {
        e.stopPropagation();
    });

    /*      slider
    /*----------------------------------------*/

    const homeSlider = $(".home-slider");

    if (homeSlider.length !== 0) {
        const { speed, autoplay, autoplaySpeed, fade, dots, arrows } =
            homeSlider.data();

        homeSlider
            .slick({
                rows: 0,
                rtl: window.FleetCart.rtl,
                cssEase: fade ? "cubic-bezier(0.7, 0, 0.3, 1)" : "ease",
                speed,
                autoplay,
                autoplaySpeed,
                fade,
                dots,
                arrows,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            dots: false,
                        },
                    },
                ],
            })
            .slickAnimation();
    }

    /*      sidebar filter
    /*----------------------------------------*/

    let mobileViewFilter = $(".mobile-view-filter");
    let filterSectionWrap = $(".filter-section-wrap");
    let sidebarFilterClose = $(".sidebar-filter-close");

    mobileViewFilter.on("click", (e) => {
        e.stopPropagation();

        filterSectionWrap.addClass("active");
        overlay.addClass("active");
    });

    sidebarFilterClose.on("click", () => {
        filterSectionWrap.removeClass("active");
        overlay.removeClass("active");
    });

    filterSectionWrap.on("click", (e) => {
        e.stopPropagation();
    });

    body.on("click", () => {
        overlay.removeClass("active");
        $(".sidebar-cart-wrap").removeClass("active");
        sidebarMenuWrap.removeClass("active");
        filterSectionWrap.removeClass("active");
    });

    /*      browse categories
    /*----------------------------------------*/

    $(".browse-categories li").each((_, li) => {
        if ($(li).children("ul").length > 0) {
            $(li).addClass("parent");
        }
    });

    let filterCategoriesLink = $(".browse-categories li.parent > a");
    let parentUls = $(".browse-categories li.active").parentsUntil(
        ".browse-categories",
        "ul"
    );

    if (window.FleetCart.rtl) {
        filterCategoriesLink.before('<i class="las la-angle-left"></i>');
    } else {
        filterCategoriesLink.before('<i class="las la-angle-right"></i>');
    }

    parentUls.show().siblings("i").addClass("open");

    $(".browse-categories li i").on("click", (e) => {

        $(".browse-categories li i").not(e.currentTarget).removeClass("open").siblings("ul").slideUp(300);

        $(e.currentTarget).toggleClass("open").siblings("ul").slideToggle(300);
    });


    $(window).on('scroll', function () {
        var scrollPos = $(document).scrollTop();
        $('.section-step').each(function (index) {
            var sectionTop = $(this).offset().top - 150;
            var sectionHeight = $(this).outerHeight();
            let value = index + 1;
            if (scrollPos >= sectionTop && scrollPos < sectionTop + sectionHeight) {
                $('input[name="flow_step"][value="' + value + '"]').prop('checked', true);
            }
        });
    });

    $('.content-left__flows input[type="radio"]').on('change', function() {
        var sectionId = $(this).val();
        $('html, body').animate({
            scrollTop: $('#flow-step-' + sectionId).offset().top - 120
        }, 1000);
    });

    $(document).ready(function() {
        // Handle click event on list items
        $('.tabs-list .tabs-item').on('click', function() {
            // Remove 'active' class from all list items
            $('.tabs-list .tabs-item').removeClass('active');
            // Add 'active' class to the clicked list item
            $(this).addClass('active');
        });

        // Handle click event on the continue button
        $('#btn-choose-register-type').on('click', function() {
            // Get the URL from the active list item
            var activeItem = $('.tabs-list .tabs-item.active');
            if (activeItem.length > 0) {
                var url = activeItem.data('url');
                // Redirect to the URL
                window.location.href = url;
            } else {
                alert('Please select an option before continuing.');
            }
        });
    });

    /*      Handle Popup Shipping Note : Themes\Storefront\views\public\cart\index.blade.php
    /*----------------------------------------*/
    $(document).ready(function() {
        $('.popup-shipping').on('click', function(event) {
            if ($(event.target).is('.popup-shipping')) {
                $(this).removeClass('active');
            }
        });

        $('.popup-fee-note').on('click', function(e) {
            e.preventDefault();
            $('.popup-shipping').addClass('active');
        });

        $('.popup-close').on('click', function(e) {
            e.preventDefault();
            $('.popup-shipping').removeClass('active');
        });
    });

    /* ============================= 3, Menu Aim Master  ============================= */
    const menuComponent = {
        init: function () {
            this.setupMenu();
            this.toggleCategory();
            this.mainMenuMobile();
            this.subMenuMobile();
            this.subMenuMobileLv2();
        },
        setupMenu: function () {
            var $menu = $(".dropdown-menu");
            $menu.menuAim({
                activate: activateSubmenu,
                deactivate: deactivateSubmenu,
            });

            function activateSubmenu(row) {
                var $row = $(row),
                    submenuId = $row.data("submenuId"),
                    $submenu = $("#" + submenuId),
                    height = $menu.outerHeight(),
                    width = $menu.outerWidth();

                $submenu.css({
                    display: "block",
                    top: -1,
                    left: width,
                    height: height,
                });

                $row.find("a").addClass("maintainHover");
            }

            function deactivateSubmenu(row) {
                var $row = $(row),
                    submenuId = $row.data("submenuId"),
                    $submenu = $("#" + submenuId);

                $submenu.css("display", "none");
                $row.find("a").removeClass("maintainHover");
            }
            $(".dropdown-menu li").click(function (e) {
                e.stopPropagation();
            });

            $(".list__item").each(function () {
                let $submenu = $(this).find(".menu__sub").first();
                let $parentMenu = $(this).closest(".menu__sub");
                if ($submenu.length) {
                    $(this).on("mouseenter", function () {
                        let parentHeight = $parentMenu.outerHeight(); // Lấy chiều cao của cấp 1
                        $submenu.css("min-height", parentHeight + "px"); // Đặt chiều cao tối thiểu
                        $submenu.stop(true, true).fadeIn(200);
                    });

                    $(this).on("mouseleave", function (e) {
                        if (!$submenu.is(e.relatedTarget) && !$submenu.has(e.relatedTarget).length) {
                            $submenu.stop(true, true).fadeOut(200);
                        }
                    });

                    $submenu.on("mouseleave", function (e) {
                        if (!$(this).is(e.relatedTarget) && !$(this).has(e.relatedTarget).length) {
                            $(this).stop(true, true).fadeOut(200);
                        }
                    });
                }
            });

            // $(".menu__category .dropdown-menu").mouseleave(function () {
            //     $(".popover").css("display", "none");
            //     $("a.maintainHover").removeClass("maintainHover");
            // });

            // $('.menu__category .dropdown-menu > li').mouseenter(function(e) {
            //     activateSubmenu(e.currentTarget)
            // })

            // $(document).click(function () {
            //     $(".popover").css("display", "none");
            //     $("a.maintainHover").removeClass("maintainHover");
            // });

            $(".list__item").mouseenter(function () {
                $(this).addClass("active");
            }).mouseleave(function () {
                $(this).removeClass("active");
            });
        },
        toggleCategory: function () {
            const categoryBtn = $(".menu__category");
            if (categoryBtn.hasClass("menu__index")) {
                categoryBtn.addClass("active");
            } else {
                categoryBtn.hover(() => {
                    categoryBtn.toggleClass("active");
                });
            }
        },
        mainMenuMobile: function () {
            const body = document.querySelector("body");
            const menuBtn = document.querySelector(".header__button.menu");
            const menuWrap = document.querySelector(".menu__list-mobile");
            const menuOverlay = document.querySelector(".menu__list__overlay");
            if (menuBtn && menuOverlay) {
                menuBtn.addEventListener("click", () => {
                    menuWrap.classList.add("active");
                    body.classList.add("modal-open");
                });
                menuOverlay.addEventListener("click", () => {
                    menuWrap.classList.remove("active");
                    body.classList.remove("modal-open");
                });
            }
        },
        subMenuMobile: function () {
            const categoryLink = $(".menu__list-mobile .menu__list__wrap > li a");
            categoryLink.bind("click", function (e) {
                const isExistSubmenu = $(this).parent().find(".popover__submenu");
                if (isExistSubmenu.length != 0) {
                    e.preventDefault();
                    $(this).parent().toggleClass("active");
                    isExistSubmenu.slideToggle("fast");
                }
            });
        },
        subMenuMobileLv2: function () {
            const categoryLink = $(
                ".menu__list-mobile .popover__submenu li > .category__link"
            );
            categoryLink.bind("click", function (e) {
                const isExistSubmenu = $(this).parent().find(".submenu__item");
                if (isExistSubmenu.length != 0) {
                    e.preventDefault();
                    isExistSubmenu.slideToggle("fast", function () {
                        if (isExistSubmenu.css("display") == "none") {
                            $(this).parent().removeClass("active");
                        } else {
                            $(this).parent().addClass("active");
                        }
                    });
                }
            });
        },
    };
menuComponent.init();
});
