<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "TipSection",
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/tip_default.png",
        },
    },
    data() {
        return {
            slickOptions: {
                arrows: false,
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                        },
                    },
                ],
            },
            tips: [],
        };
    },
    methods: {
        prevSlide() {
            this.$refs.slick.prev();
        },
        nextSlide() {
            this.$refs.slick.next();
        },
    },
    beforeMount() {
        this.tips = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="news-section">
        <div class="container">
            <div class="news-content">
                <div class="news-content__title">
                    <h3>Latest new</h3>
                </div>
                <div class="news-content__list">
                    <VueSlickCarousel ref="slick" v-bind="slickOptions">
                        <div class="content__list-item" v-for="item in tips.blogs.data" :key="item.id">
                            <a :href="'bai-viet/' + item.slug" class="news-article-item">
                                <div class="news-article">
                                    <div class="news-top">
                                        <img
                                            :src="item.image_path ?? defaultImage"
                                            :alt="item.name"
                                            class="news-article-image"
                                        />
                                    </div>
                                    <div class="news-article-body">
                                        <p>Blog</p>
                                        <p class="news-created">{{ item.created_at_format ?? '' }}</p>
                                    </div>
                                    <h3 class="news-article-title">
                                        {{ item.name }}
                                    </h3>
                                </div>
                            </a>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
        <div class="news-pagination">
            <div class="news-pagination-controls">
                <button class="news-pagination-next" @click="nextSlide">
                    <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/43d0cf80b0f1326bf7a71fe72cc885b6e65b31e6a733df1d97dcb3e706a0b9af?apiKey=f4720969da584a5080659c2fbf41bfab&"
                        alt="Next"
                        class="news-pagination-icon"
                    />
                </button>
            </div>
        </div>
    </section>
</template>
