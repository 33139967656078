<script>
export default {
    name: 'TopSearch',
    props: {
        data: String,
    },
    data() {
        return {
            topSearch: {},
            logo1: String,
            logo2: String,
            logo3: String,
            logo4: String,
            logo5: String,
            title1: String,
            title2: String,
            title3: String,
            title4: String,
            title5: String,
            productUrl1: String,
            productUrl2: String,
            productUrl3: String,
            productUrl4: String,
            productUrl5: String
        };
    },
    beforeMount() {
        this.topSearch = JSON.parse(this.data);
        console.log(this.topSearch.top_search_title_1);
        this.logo1 = this.topSearch.logo.logo_1.image.path;
        this.logo2 = this.topSearch.logo.logo_2.image.path;
        this.logo3 = this.topSearch.logo.logo_3.image.path;
        this.logo4 = this.topSearch.logo.logo_4.image.path;
        this.logo5 = this.topSearch.logo.logo_5.image.path;
        this.title1 = this.topSearch.top_search_title_1;
        this.title2 = this.topSearch.top_search_title_2;
        this.title3 = this.topSearch.top_search_title_3;
        this.title4 = this.topSearch.top_search_title_4;
        this.title5 = this.topSearch.top_search_title_5;
        this.productUrl1 = this.topSearch.top_search_url_1;
    }
};
</script>

<template>
    <section class="applications-wrap">
        <div class="container">
            <div class="applications">
                <h2 class="section-title-menu">{{ topSearch.title }}</h2>
                <div class="application-list">
                    <div class="single-application">
                        <a :href="productUrl1">
                            <div class="application-icon full-image">
                                <img :src="logo1" :alt="title1">
                            </div>

                            <div class="application-details">
                                <h6 class="details-name">{{ topSearch.top_search_title_1 }}</h6>
                            </div>
                        </a>
                    </div>
                    <div class="single-application">
                        <a :href="productUrl2">
                            <div class="application-icon full-image">
                                <img :src="logo2" :alt="title2">
                            </div>

                            <div class="application-details">
                                <h6 class="details-name">{{ topSearch.top_search_title_2 }}</h6>
                            </div>
                        </a>
                    </div>
                    <div class="single-application">
                        <a :href="productUrl3">
                            <div class="application-icon full-image">
                                <img :src="logo3" :alt="title3">
                            </div>

                            <div class="application-details">
                                <h6 class="details-name">{{ topSearch.top_search_title_3 }}</h6>
                            </div>
                        </a>
                    </div>
                    <div class="single-application">
                        <a :href="productUrl4">
                            <div class="application-icon full-image">
                                <img :src="logo4" :alt="title4">
                            </div>

                            <div class="application-details">
                                <h6 class="details-name">{{ topSearch.top_search_title_4 }}</h6>
                            </div>
                        </a>
                    </div>
                    <div class="single-application">
                        <a :href="productUrl5">
                            <div class="application-icon full-image">
                                <img :src="logo5" :alt="title5">
                            </div>

                            <div class="application-details">
                                <h6 class="details-name">{{ topSearch.top_search_title_5 }}</h6>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
