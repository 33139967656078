<script>
export default {
    name: 'RawMaterials',
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/product_default.png"
        }
    },
    data() {
        return {
            materials: {}
        };
    },
    beforeMount() {
        this.materials = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="materials-wrap" v-if="materials.data && materials.data.length > 0">
        <div class="container">
            <div class="materials">
                <h2 class="section-title-menu">{{ materials.title }}</h2>
                <div class="material-list">
                    <div class="single-material"  v-for="item in materials.data" :key="item.id">
                        <div class="material-icon">
                            <a href="/products"><img :src="item.logo?.path" alt=""></a>
                        </div>

                        <div class="material-details">
                            <h6 class="details-name"><a href="/products">{{ item.name }}</a></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
