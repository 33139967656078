<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    name: 'BestSellerProducts',
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/product_default.png"
        }
    },
    data() {
        return {
            // slickOptions: {
            //     arrows: false,
            //     dots: true,
            //     infinite: true,
            //     speed: 500,
            //     slidesToShow: 4,
            //     slidesToScroll: 1,
            //     dotsClass: "slick-dots dots-vision",
            //     responsive: [
            //         {
            //             breakpoint: 992,
            //             settings: {
            //                 slidesToShow: 2,
            //                 slidesToScroll: 1
            //             },
            //         },
            //         {
            //             breakpoint: 768,
            //             settings: {
            //                 slidesToShow: 1,
            //                 slidesToScroll: 1
            //             },
            //         },
            //     ],
            // },
            products: {},
        };
    },
    beforeMount() {
        this.products = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="product-section container" v-if="products.products && products.products.length > 0">
        <h2 class="section-title-menu">{{ products.title }}</h2>
        <div class="product-grid-ob">
            <div class="product-list-item" v-for="item in products.products" :key="item.id">
                <a :href="'products/' + item.slug">
                    <div class="product-column">
                        <div class="product-card">
                            <img
                                :src="item.base_image.path ?? defaultImage"
                                :alt="item.name"
                                class="product-image-menu"
                            />
                            <div class="product-details">
                                <h3 class="product-name-menu">
                                    {{ item.name }}
                                </h3>
                                <div class="product-price-range" v-html="item.formatted_price"></div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="home-see-more">
            <a href="/products" class="btn btn-primary">{{ $trans('storefront::layout.see_more') }}</a>
        </div>
    </section>
</template>
