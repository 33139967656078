<script>
export default {
    name: 'TopSearchProducts',
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/product_default.png"
        }
    },
    data() {
        return {
            // slickOptions: {
            //     arrows: false,
            //     dots: true,
            //     infinite: true,
            //     speed: 500,
            //     slidesToShow: 4,
            //     slidesToScroll: 1,
            //     dotsClass: "slick-dots dots-vision",
            //     responsive: [
            //         {
            //             breakpoint: 992,
            //             settings: {
            //                 slidesToShow: 2,
            //                 slidesToScroll: 1
            //             },
            //         },
            //         {
            //             breakpoint: 768,
            //             settings: {
            //                 slidesToShow: 1,
            //                 slidesToScroll: 1
            //             },
            //         },
            //     ],
            // },
            topProducts: {}
        };
    },
    beforeMount() {
        this.topProducts = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="container" v-if="topProducts.products && topProducts.products.length > 0">
        <div class="product-section-page">
            <h2 class="section-title-menu">FGS top search</h2>
            <div class="product-grid-page">
                <div class="product-list-item" v-for="item in topProducts.products" :key="item.id">
                    <a :href="'products/' + item.slug">
                        <div class="product-column">
                            <div class="product-card">
                                <img :src="item.base_image.path ?? defaultImage" :alt="item.name"
                                    class="product-image-menu" />
                                <div class="product-details">
                                    <h3 class="product-name-menu">
                                        {{ item.name }}
                                    </h3>
                                    <div class="product-price-range" v-html="item.formatted_price"></div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>
