<script>
export default {
    name: 'RalatedProductsV2',
    props: ["products"]
};
</script>

<template>
    <section class="product-section container" v-if="products && products.length > 0">
        <h2 class="section-title-menu">{{ $trans("storefront::product.related_products") }}</h2>
        <div class="product-grid-related">
            <div class="product-list-item" v-for="item in products" :key="item.id">
                <a :href="'products/' + item.slug">
                    <div class="product-column">
                        <div class="product-card">
                            <img
                                :src="item.base_image.path"
                                :alt="item.name"
                                class="product-image-menu"
                            />
                            <div class="product-details">
                                <h3 class="product-name-menu">
                                    {{ item.name }}
                                </h3>
                                <div class="product-price-range" v-html="item.formatted_price"></div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="home-see-more">
            <a href="/products" class="btn btn-primary">{{ $trans('storefront::layout.see_more') }}</a>
        </div>
    </section>
</template>
